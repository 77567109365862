import { sum, map, filter, uniqBy } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import axios_playz from '../../utils/axios_playz';

// ----------------------------------------------------------------------

const baseShipping = 12;

const initialState = {
  isLoading: false,
  error: false,
  products: [],
  product: null,
  sortBy: null,
  filters: {
    gender: [],
    category: 'All',
    colors: [],
    priceRange: '',
    rating: ''
  },
  checkout: {
    activeStep: 0,
    cart: [],
    subtotal: 0,
    total: 0,
    discount: 0,
    shipping: baseShipping,
    shipType: 'regular',
    shipto: null,
    stripe: null,
    elements:null,
    orderNumber: 0,
    message: '',
    options: []
  }
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getProductsSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload;
    },

    // GET PRODUCT
    getProductSuccess(state, action) {
      state.isLoading = false;
      state.product = action.payload;
      state.product.pid = state.product.id;
    },

    // SET PRODUCT CARD (cover, quantity)
    setProductCard(state, action) {
      state.isLoading = false;
      const card = action.payload;
      const cardId = card.card_id;
      const cardCover = card.photo;
      state.product.cover = cardCover;
      state.product.cid = cardId;
      state.product.id = state.product.pid + '|' + cardId;
      state.product.quantity = 1;
    },

    //  SORT & FILTER PRODUCTS
    sortByProducts(state, action) {
      state.sortBy = action.payload;
    },

    filterProducts(state, action) {
      state.filters.gender = action.payload.gender;
      state.filters.category = action.payload.category;
      state.filters.colors = action.payload.colors;
      state.filters.priceRange = action.payload.priceRange;
      state.filters.rating = action.payload.rating;
    },

    // CHECKOUT
    getCart(state, action) {
      const cart = action.payload;

      const subtotal = sum(
        cart.map((product) => product.price * product.quantity)
      );
      const discount = cart.length === 0 ? 0 : state.checkout.discount;
      const shipping = cart.length === 0 ? baseShipping : state.checkout.shipping;
      const shipto = cart.length === 0 ? null : state.checkout.shipto;

      state.checkout.cart = cart;
      state.checkout.discount = discount;
      state.checkout.shipping = shipping;
      state.checkout.shipto = shipto;
      state.checkout.subtotal = subtotal;
      state.checkout.total = subtotal - discount + shipping;
    },

    addCart(state, action) {
      const product = action.payload;
      const isEmptyCart = state.checkout.cart.length === 0;

      if (isEmptyCart) {
        state.checkout.cart = [...state.checkout.cart, product];
      } else {
        state.checkout.cart = map(state.checkout.cart, (_product) => {
          const isExisted = _product.id === product.id;
          if (isExisted) {
            return {
              ..._product,
              quantity: _product.quantity + 1
            };
          }
          return _product;
        });
      }
      state.checkout.cart = uniqBy([...state.checkout.cart, product], 'id');
    },

    deleteCart(state, action) {
      const updateCart = filter(
        state.checkout.cart,
        (item) => item.id !== action.payload
      );

      state.checkout.cart = updateCart;
    },

    resetCart(state) {
      state.checkout.activeStep = 0;
      state.checkout.cart = [];
      state.checkout.total = 0;
      state.checkout.subtotal = 0;
      state.checkout.discount = 0;
      state.checkout.shipping = baseShipping;
      state.checkout.shipType = 'regular';
      state.checkout.shipto = null;
      state.checkout.stripe = null;
      state.checkout.elements = null;
      state.checkout.orderNumber = 0;
      state.checkout.message = ''
      state.checkout.options = [];
    },

    onBackStep(state) {
      state.checkout.activeStep -= 1;
    },

    onNextStep(state) {
      state.checkout.activeStep += 1;
    },

    onGotoStep(state, action) {
      const goToStep = action.payload;
      state.checkout.activeStep = goToStep;
    },

    increaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = map(state.checkout.cart, (product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity + 1
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    decreaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = map(state.checkout.cart, (product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity - 1
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    createBilling(state, action) {
      state.checkout.shipto = action.payload;
    },

    applyDiscount(state, action) {
      const discount = action.payload;
      state.checkout.discount = discount;
      state.checkout.total = state.checkout.subtotal - discount;
    },

    applyShipping(state, action) {
      const shipStr = action.payload;
      var tmp_array = shipStr.split('|');
      var shipping = Number(tmp_array[0]);
      var shipType= tmp_array[1];
      state.checkout.shipping = shipping;
      state.checkout.shipType = shipType;
      state.checkout.total =
        state.checkout.subtotal - state.checkout.discount + shipping;
    },
    //  GET SHIPPING
    getShippingSuccess(state, action) {
      const result = action.payload;
      state.isLoading = false;
      //console.log('getShippingSuccess result', result);
      state.checkout.options = result.options;
      state.checkout.shipping = result.shipping;
      state.checkout.total = state.checkout.subtotal - state.checkout.discount + result.shipping;
    },

    
    
    // SAVE ORDER
    setStripe(state, action) {
      const stripe = action.payload;
      state.checkout.stripe = stripe;
    },    
    setElements(state, action) {
      const elements = action.payload;
      state.checkout.elements = elements;
    },
    setMessage(state, action) {
      const message = action.payload;
      //console.log('setting message', message);
      state.checkout.message = message;
    },
    saveOrderSuccess(state, action) {
      state.isLoading = false;
      state.checkout.orderNumber = action.payload;
    },
    updateOrderSuccess(state, action) {
      state.isLoading = false;
      //state.checkout.orderNumber = action.payload;
    } 
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getCart,
  addCart,
  resetCart,
  onGotoStep,
  onBackStep,
  onNextStep,
  deleteCart,
  createBilling,
  applyShipping,
  applyDiscount,
  increaseQuantity,
  decreaseQuantity,
  sortByProducts,
  filterProducts,
  setProductCard,
  setStripe,
  setElements,
  setMessage
  
} = slice.actions;

// ----------------------------------------------------------------------

export function getProducts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/products');
      dispatch(slice.actions.getProductsSuccess(response.data.products));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProduct(name) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/products/product', {
        params: { name }
      });
      dispatch(slice.actions.getProductSuccess(response.data.product));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProducts_Playz() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios_playz.get('/get/products');
      dispatch(slice.actions.getProductsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProduct_Playz(id) {
  return async (dispatch) => {
    //console.log('getting product 2', id);
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios_playz.get('/get/product', {
        params: {
          id: id
        }
      });
      //console.log('get/product response', response);
      dispatch(slice.actions.getProductSuccess(response.data));
    } catch (error) {
      dispatch('get product error', slice.actions.hasError(error));
    }
  };
}

export function saveOrder(formdata) {
  return async (dispatch) => {
    //console.log('in saveOrder');
    dispatch(slice.actions.startLoading());
    //console.log('formdata', formdata);
    try {
      const response = await axios_playz.post('/save/order', formdata, {
        headers: { 'X-Requested-With': 'XMLHttpRequest' }
      });
      //console.log('response.data', response.data);
      dispatch(slice.actions.saveOrderSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateOrder(formdata) {
  return async (dispatch) => {
    //console.log('in updateOrder');
    dispatch(slice.actions.startLoading());
    //console.log('formdata', formdata);
    try {
      const response = await axios_playz.post('/save/order_update', formdata, {
        headers: { 'X-Requested-With': 'XMLHttpRequest' }
      });
      //console.log('response.data', response.data);
      dispatch(slice.actions.updateOrderSuccess(response.data.url));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function saveOrderCSV(formdata) {
  return async (dispatch) => {
    //console.log('in saveOrderCSV');
    dispatch(slice.actions.startLoading());
    //console.log('formdata', formdata);
    try {
      const response = await axios_playz.post('/save/order_csv', formdata, {
        headers: { 'X-Requested-With': 'XMLHttpRequest' }
      });
      //console.log('response.data', response.data);
      dispatch(slice.actions.updateOrderCSVSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getShipping(cart, ship_type='regular') {
  return async (dispatch) => {
    //console.log('getting product 2', id);
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios_playz.get('/get/shipping', {
        params: {
          cart: JSON.stringify(cart),
          ship_type: ship_type
        }
      });
      //console.log('get/shipping response', response);
      dispatch(slice.actions.getShippingSuccess(response.data));
    } catch (error) {
      dispatch('get shipping error', slice.actions.hasError(error));
    }
  };
}
