import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import { PATH_PAGE, PATH_APP } from '../routes/paths';
// components
import LoadingScreen from '../components/LoadingScreen';
import { useSelector } from 'react-redux';

// ----------------------------------------------------------------------

AuthProtect.propTypes = {
  children: PropTypes.node
};

export default function AuthProtect({ children }) {
  const { isLoading, isAuthenticated, user } = useAuth();
  const { myProfile } = useSelector((state) => state.user);
  const pathname = window.location.pathname;
  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    return <Redirect to={PATH_PAGE.auth.login} />;
  }
  /*
  //console.log('pathname');
  //console.log(pathname);
  //console.log('myProfile');
  //console.log(myProfile);
  //console.log('myProfile.profiles');
  //console.log(myProfile.profiles);
  //console.log('myProfile.roles');
  //console.log(myProfile.roles);
  //console.log('user.user_current_profile');
  //console.log(user.user_current_profile);
  //console.log('user.userid');
  //console.log(user.userid);
  //console.log('myProfile.id');
  //console.log(myProfile.id);
  */
  if (
    pathname != '/app/settings/sports' &&
    user.userid == myProfile.id &&
    Object.keys(myProfile).length &&
    (!myProfile.profiles.length || !myProfile.roles.length)
  ) {
    //console.log('redirecting to settings sports');
    return <Redirect to={PATH_APP.settings.home + '/sports'} />;
  }

  return <>{children}</>;
}
