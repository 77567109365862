// material
import { Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

export default function Logo({ lined = false, logoheight = 50, ...other }) {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  var which_logo = '/static/brand/playz-white.png?v=2';
  if (lined) {
    var which_logo = '/static/brand/playz-black.png?v=2';
  } else if (isLight) {
    which_logo = '/static/brand/playz-black.png?v=2';
  }
  //console.log('height is ' + logoheight);
  return (
    <Box
      component="img"
      alt="logo"
      src={which_logo}
      height={logoheight}
      {...other}
    />
  );
}
