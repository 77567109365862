import axios from 'axios';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: 'https://playz.fan'
});
axiosInstance.interceptors.response.use(
  (response) => {
    //console.log('Response:', JSON.stringify(response, null, 2));
    return response;
  },
  (error) => {
    //console.log('Error:', JSON.stringify(error, null, 2));
    Promise.reject(
      (error.response && error.response.data) || 'Something went wrong'
    );
  }
);
/*
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || 'Something went wrong'
    )
);
*/

export default axiosInstance;
