import PropTypes from 'prop-types';
import SimpleBarReact from 'simplebar-react';
// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  height: '100%',
  overflow: 'hidden'
}));

const SimpleBarStyle = styled(SimpleBarReact)(({ theme }) => ({
  maxHeight: '100%',
  '& .simplebar-scrollbar': {
    '&:before': {
      backgroundColor: alpha(theme.palette.grey[600], 0.48)
    },
    '&.simplebar-visible:before': {
      opacity: 1
    }
  },
  '& .simplebar-track.simplebar-vertical': {
    width: 10
  },
  '& .simplebar-track.simplebar-horizontal .simplebar-scrollbar': {
    height: 6
  },
  '& .simplebar-mask': {
    zIndex: 'inherit'
  },
  '& .simplebar-content-wrapper::-webkit-scrollbar': {
    display: 'none'
  }
}));

// ----------------------------------------------------------------------

Scrollbars.propTypes = {
  children: PropTypes.node.isRequired
};

export default function Scrollbars({ children, ...other }) {
  return (
    <RootStyle>
      <SimpleBarStyle timeout={500} clickOnTrack={false} {...other}>
        {children}
      </SimpleBarStyle>
    </RootStyle>
  );
}
