import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, matchPath } from 'react-router-dom';
// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import {
  Box,
  Link,
  List,
  Drawer,
  Hidden,
  Typography,
  ListSubheader,
  IconButton
} from '@material-ui/core';
// hooks
import useAuth from '../../hooks/useAuth';
// routes
import { PATH_APP } from '../../routes/paths';
// components
import Logo from '../../components/Logo';
import MyAvatar from '../../components/MyAvatar';
import Scrollbars from '../../components/Scrollbars';
//
import MenuLinks from './SidebarConfig';
import SidebarItem from './SidebarItem';
import Settings from 'src/components/settings';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';


// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  margin: theme.spacing(1, 2.5, 5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12]
}));

// ----------------------------------------------------------------------

function reduceChild({ array, item, user, pathname, level }) {
  const key = item.href + level;

  var showMenuItem = false;

  if (user.isRep){ // SHOW ALL AND rep menu
    if (!item.printer){
      showMenuItem = true;
    }
  } else if (user.isPrinter){ // SHOW ONLY printer menu
    if (item.printer){
      showMenuItem = true;
    }
  } else if (!item.rep && !item.printer){ // SHOW ALL BUT rep and printer
    showMenuItem = true;
  } 

  if (showMenuItem){
    if (item.items) {
      const match = matchPath(pathname, {
        path: item.href,
        exact: false
      });

      array = [
        ...array,
        <SidebarItem
          key={key}
          level={level}
          icon={item.icon}
          info={item.info}
          href={item.href}
          title={item.title}
          open={Boolean(match)}
        >
          {renderSidebarItems({
            pathname,
            level: level + 1,
            items: item.items
          })}
        </SidebarItem>
      ];
    } else {
      array = [
        ...array,
        <SidebarItem
          key={key}
          level={level}
          href={item.href}
          icon={item.icon}
          info={item.info}
          title={item.title}
        />
      ];
    }
  }
  return array;
}

function renderSidebarItems({ items, pathname, user, level = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (array, item) => reduceChild({ array, item, user, pathname, level }),
        []
      )}
    </List>
  );
}

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function DashboardSidebar({ user, isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();

// -------ADD SALES REP IF REP------------------------------------

  useEffect(() => {
    if (isOpenSidebar && onCloseSidebar) {
      onCloseSidebar(); // REMOVING THIS MADE THE BUTTON WORK FOR SOME REASON
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbars>
      <Box sx={{ px: 4, py: 3 }}>
        <RouterLink to="/">
          <Logo logoheight={40} />

        </RouterLink>
        {/*
        <IconButton
          onClick={onCloseSidebar}
          sx={{ mr: 1, color: 'text.primary' }}
        >
          <Icon icon={menu2Fill} />
        </IconButton>
        */}
      </Box>

      <Link underline="none" component={RouterLink} to={PATH_APP.profile.home}>
        <AccountStyle>
          <MyAvatar user={user}/>
          <Box sx={{ ml: 2 }}>
            <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
              {user.displayName}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {user.role}
            </Typography>
          </Box>
        </AccountStyle>
      </Link>

      {MenuLinks.map((list) => (
          <List
            disablePadding
            key={list.subheader}
            subheader={
              <ListSubheader
                disableSticky
                disableGutters
                sx={{
                  mt: 3,
                  mb: 2,
                  pl: 5,
                  color: 'text.primary',
                  typography: 'overline'
                }}
              >
                {list.subheader}
              </ListSubheader>
            }
          >
            {renderSidebarItems({
              items: list.items,
              user: user,
              pathname
            })}
          </List>
      ))}
      <Settings />
    </Scrollbars>
  );

  return (
    <RootStyle>
      <Hidden lgUp>
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: { width: DRAWER_WIDTH, bgcolor: 'background.default' }
          }}
        >
          {renderContent}
        </Drawer>
      </Hidden>
    </RootStyle>
  );
}
