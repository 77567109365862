import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, AppBar, Hidden, Toolbar, IconButton, Button } from '@material-ui/core';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import NotificationsPopover from './NotificationsPopover';
import Logo from 'src/components/Logo';
import { Link as RouterLink } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

const PlayzStyle = styled('div')(({ theme }) => ({
  /*
  zIndex: 101,
  paddingTop: 10,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  marginBottom: -60,
  [theme.breakpoints.up('lg')]: {
    paddingTop: 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: -20
  }
  */
}));
// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({ user, loggedIn, onOpenSidebar, logout }) {
  //console.log('loggedIn in DashboardNavbar', loggedIn);

  return (
    <RootStyle style={loggedIn == '' ? {width: '100%'} : {RootStyle}}>
      <ToolbarStyle>
        {loggedIn == '' ? (
          <PlayzStyle>
            <RouterLink to="/">
              <Logo logoheight={40} />
            </RouterLink>
          </PlayzStyle>
        ) : (
          <Hidden lgUp>
            <IconButton
              onClick={onOpenSidebar}
              sx={{ mr: 1, color: 'text.primary' }}
            >
              <Icon icon={menu2Fill} />
            </IconButton>
          </Hidden>
        )}

        <Searchbar />
        <Box sx={{ flexGrow: 1 }} />

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            '& > *:not(:first-of-type)': {
              ml: { xs: 1.5, sm: 2, lg: 3 }
            }
          }}
        >
        {loggedIn == '' ? (
          <Box>
            <RouterLink to="/auth/register"><Button>Sign Up</Button></RouterLink>
            /
            <RouterLink to="/auth/login"><Button>Sign In</Button></RouterLink>
          </Box>
        ) : (
          /*<NotificationsPopover />*/
          <AccountPopover user={user} logout={logout} />
        )}
        </Box>
      </ToolbarStyle>
    </RootStyle>
  );
}
