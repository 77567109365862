import PropTypes from 'prop-types';
import { useState } from 'react';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import useAuth from 'src/hooks/useAuth';
import { useSelector } from 'react-redux';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')(({ theme }) => ({
  zIndex: 100,
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
}));

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

DashboardLayout.propTypes = {
  children: PropTypes.node
};

export default function DashboardLayout({ children }) {
  const [open, setOpen] = useState(false);
  const { loggedIn } = useSelector((state) => state.user);
  const { user, logout } = useAuth();

  //console.log('loggedIn in DashboardLayout', loggedIn);
  return (
    <>
      <RootStyle>
        <DashboardNavbar user={user} loggedIn={loggedIn} onOpenSidebar={() => setOpen(true)} logout={logout} />
        {loggedIn != '' && (
          <>
            <DashboardSidebar
              user={user}
              isOpenSidebar={open}
              onCloseSidebar={() => setOpen(false)}
              onOpenSidebar={() => setOpen(true)}
            />
          </>
        )}
        <MainStyle>{children}</MainStyle>
      </RootStyle>
    </>
  );
}
