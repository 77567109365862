import { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import DashboardLayout from '../layouts/dashboard';
import { PATH_APP } from '../routes/paths';
// ----------------------------------------------------------------------

const AliasRoutes = {
  path: '/',
  layout: DashboardLayout,
  routes: [
    {
      exact: true,
      path: '/:alias',
      component: lazy(() => import('../views/CheckAlias'))
    },
    {
      exact: true,
      path: '/',
      //component: lazy(() => import('../views/News'))
      // NEED TO CHANGE THIS WHEN LIVE
      component: () => <Redirect to={PATH_APP.news.home} />
    }
  ]
};

export default AliasRoutes;
