import { Icon } from '@iconify/react';
import { useState, useCallback } from 'react';
import { useDispatch, useSelector  } from 'react-redux';
import searchFill from '@iconify/icons-eva/search-fill';
import { useParams, useHistory } from 'react-router-dom';
import { PATH_APP } from 'src/routes/paths';

// material
import { experimentalStyled as styled, alpha } from '@material-ui/core/styles';
import {
  Box,
  Input,
  Slide,
  Button,
  InputAdornment,
  ClickAwayListener
} from '@material-ui/core';
// components
import { MIconButton } from '../../components/@material-extend';
import { setSearchString } from 'src/redux/slices/blog';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const SearchbarStyle = styled('div')(({ theme }) => ({
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: APPBAR_MOBILE,
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  backgroundColor: `${alpha(theme.palette.background.default, 0.72)}`,
  [theme.breakpoints.up('md')]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

export default function Searchbar() {
  const [isOpen, setOpen] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');

  const searchFunction = useCallback(async () => {
    try {
      //console.log('setting search', search)
      await dispatch(setSearchString(search));
      history.push(PATH_APP.search.home);
    } catch (err) {
      //
    }
  }, [dispatch, history, search]);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    var s = event.target.value;
    //console.log('handleChange', s);
    s = s.replace(/[^\w\s!?]/g,'');

    setSearch(s);
  };
  const handleKeyPress = (e) => {
    if(e.keyCode == 13){
      handleSearch();
    }    
  }  
  const handleSearch = () => {
    //console.log('search', search);
    //console.log('search.length', search.length);
    if (search.length > 3) {
      searchFunction(search);
    };
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!isOpen && (
          <MIconButton onClick={handleOpen}>
            <Icon icon={searchFill} width={20} height={20} />
          </MIconButton>
        )}

        <Slide direction="down" in={isOpen} mountOnEnter unmountOnExit>
          <SearchbarStyle>
            <Input
              fullWidth
              disableUnderline
              placeholder="Search…"
              value={search}
              onChange={handleChange}
              onKeyDown={handleKeyPress}
              startAdornment={
                <InputAdornment position="start">
                  <Box
                    component={Icon}
                    icon={searchFill}
                    sx={{ color: 'text.disabled', width: 20, height: 20 }}
                  />
                </InputAdornment>
              }
              sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
            />
            <Button variant="contained" onClick={handleSearch}>
              Search
            </Button>
          </SearchbarStyle>
        </Slide>
      </div>
    </ClickAwayListener>
  );
}
