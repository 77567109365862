import { map, reject } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
//import axios from '../../utils/axios';
import axios from '../../utils/axios_playz';
import { fDateTimeMySQL } from 'src/utils/formatTime';

// ----------------------------------------------------------------------
 
const initialState = {
  isLoading: false,
  error: false,
  events: [],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
  groups: [],
  updateEvents: false,
  calendarsFollowing: [],
  resetFollowing: true,
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getEventsSuccess(state, action) {
      state.isLoading = false;
      state.events = action.payload;
      state.updateEvents = false;
      //console.log('got events', state.events.length);
    },

    // CREATE EVENT
    createEventSuccess(state, action) {
      const newEvent = action.payload;
      state.isLoading = false;
      state.events = [...state.events, newEvent];
      state.updateEvents = true;
    },

    // UPDATE EVENT
    updateEventSuccess(state, action) {
      const event = action.payload;
      const updateEvent = map(state.events, (_event) => {
        if (_event.id === event.id) {
          return event;
        }
        return _event;
      });
      state.events = updateEvent; // reloads so maybe not needed (updates quicker at least)
      
      state.isLoading = false;
      state.updateEvents = true;
    },

    // DELETE EVENT
    deleteEventSuccess(state, action) {
      const { eventId, recurringOption, dateGroup } = action.payload;
      var deleteEvent = reject(state.events, { id: eventId });
      if (recurringOption == 'all' && dateGroup){
        deleteEvent = reject(deleteEvent, { dateGroup: dateGroup });
      }

      state.isLoading = false;
      state.events = deleteEvent; // ITS GOING TO UPDATE THIS ANYWAY AFTER
      state.updateEvents = true;
    },
    // GET GROUPS
    getGroupsSuccess(state, action) {
      state.isLoading = false;
      state.groups = action.payload;
      state.groupNewId = '';
    },

    // SELECT EVENT
    selectEvent(state, action) {
      const eventId = action.payload;
      state.isOpenModal = true;
      state.selectedEventId = eventId;
    },

    // SELECT RANGE
    selectRange(state, action) {
      const { start, end, allDay } = action.payload;
      state.isOpenModal = true;
      state.selectedRange = { start, end, allDay };
    },

    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    },
    clearEvents(state) {
      //console.log('clearing events');
      state.events = [];
      state.updateEvents = true;
    },    
    getCalendarsFollowingSuccess(state, action) {
      state.isLoading = false;
      state.calendarsFollowing = action.payload;
      state.resetFollowing = false;
    },    
    onToggleFollowCalendar(state, action) {
      // no need to do anything cause it will reset 
      state.resetFollowing = true;
    },   
    reGetEvents(state) {
      //console.log('should be updating events');
      state.updateEvents = true;
    },        
    stopGetEvents(state) {
      //console.log('stop updating events');
      state.updateEvents = false;
    },     
    saveUserCalendarSuccess(state, action) {
      state.isSaving = false;
      //state.card.card_id = card_id;
      //getCard(action.payload);
    },       
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent, clearEvents, reGetEvents, stopGetEvents } = slice.actions;

// ----------------------------------------------------------------------

export function getEvents(viewer, user='', group='', type='', which='') {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.stopGetEvents());
    //console.log('getEvents', viewer, user, group, which);
    try {
      const response = await axios.get('/get/events', {
        params: { 
          viewer: viewer,
          user: user,
          group: group,
          type: type,
          which: which
         }
      });
      //console.log(response.data);
      dispatch(slice.actions.getEventsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createEvent(user, newEvent) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    var formdata = new FormData();
    // title, description, textColor, allDay, end, start
    //console.log('newEvent');
    //console.log(newEvent);
    formdata.append('event_name', newEvent.title);
    formdata.append('event_description', newEvent.description);
    formdata.append('event_text_color', newEvent.textColor);
    formdata.append('event_all_day', newEvent.allDay);
    formdata.append('event_start', fDateTimeMySQL(newEvent.start));
    formdata.append('event_end', fDateTimeMySQL(newEvent.end));
    formdata.append('event_user', user);

    formdata.append('event_group', newEvent.team);
    formdata.append('event_recurring', newEvent.recurring);
    formdata.append('event_recurring_every', newEvent.recurringEvery);
    formdata.append('event_recurring_until', fDateTimeMySQL(newEvent.recurringUntil));
    formdata.append('event_date_group', newEvent.dateGroup);
    formdata.append('event_location', newEvent.location);
    formdata.append('event_type', newEvent.eventType);
    formdata.append('event_hometeam', newEvent.homeTeam);
    formdata.append('event_awayteam', newEvent.awayTeam);
    formdata.append('event_homescore', newEvent.homeScore);
    formdata.append('event_awayscore', newEvent.awayScore);
    formdata.append('event_public', newEvent.public);

    try {
      const response = await axios.post('/save/event', formdata);
      newEvent.id = response.data;
      dispatch(slice.actions.createEventSuccess(newEvent));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateEvent(eventId, updateEvent) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    var formdata = new FormData();
    // title, description, textColor, allDay, end, start
    //console.log('****************************************updateEvent');
    //console.log(updateEvent);
    formdata.append('event_id', eventId);
    updateEvent.title != undefined && formdata.append('event_name', updateEvent.title);
    updateEvent.description != undefined && formdata.append('event_description', updateEvent.description);
    updateEvent.textColor != undefined && formdata.append('event_text_color', updateEvent.textColor);
    updateEvent.allDay != undefined && formdata.append('event_all_day', updateEvent.allDay);
    updateEvent.start != undefined && formdata.append('event_start', fDateTimeMySQL(updateEvent.start));
    updateEvent.end != undefined && formdata.append('event_end', fDateTimeMySQL(updateEvent.end));
    updateEvent.user != undefined && formdata.append('event_user', updateEvent.user);

    updateEvent.team != undefined && formdata.append('event_group', updateEvent.team);
    updateEvent.recurring != undefined && formdata.append('event_recurring', updateEvent.recurring);
    updateEvent.recurringEvery != undefined && formdata.append('event_recurring_every', updateEvent.recurringEvery);
    updateEvent.recurringUntil != undefined && formdata.append('event_recurring_until', fDateTimeMySQL(updateEvent.recurringUntil));
    updateEvent.recurringOption != undefined && formdata.append('recurringOption', updateEvent.recurringOption);
    updateEvent.dateGroup != undefined && formdata.append('event_date_group', updateEvent.dateGroup);
    updateEvent.location != undefined && formdata.append('event_location', updateEvent.location);
    updateEvent.eventType != undefined && formdata.append('event_type', updateEvent.eventType);
    updateEvent.homeTeam != undefined && formdata.append('event_hometeam', updateEvent.homeTeam);
    updateEvent.awayTeam != undefined && formdata.append('event_awayteam', updateEvent.awayTeam);
    updateEvent.homeScore != undefined && formdata.append('event_homescore', updateEvent.homeScore);
    updateEvent.awayScore != undefined && formdata.append('event_awayscore', updateEvent.awayScore);
    updateEvent.public != undefined && formdata.append('event_public', updateEvent.public);

    try {
      const response = await axios.post('/save/event', formdata);
      updateEvent.id = eventId;
      dispatch(slice.actions.updateEventSuccess(updateEvent));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteEvent(eventId, recurringOption='', dateGroup='') {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    var formdata = new FormData();
    formdata.append('id', eventId);
    formdata.append('recurringOption', recurringOption);
    try {
      //console.log('deleting ' + eventId);
      const response = await axios.post('/delete/event', formdata);
      //console.log('response ' + response.data);
      dispatch(slice.actions.deleteEventSuccess({ eventId, recurringOption, dateGroup }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function selectRange(start, end, allDay) {
  return async (dispatch) => {
    dispatch(
      slice.actions.selectRange({
        start: start.getTime(),
        end: end.getTime(),
        allDay: allDay
      })
    );
  };
}

export function getGroups(user_id, profile_id = '', is_manager=0) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    //console.log('getGroups', user_id, profile_id, is_manager);
    try {
      const response = await axios.get('/get/groups', {
        params: {
          user_id: user_id,
          profile_id: profile_id,
          is_manager: is_manager
        }
      });
      //console.log(response.data);
      dispatch(slice.actions.getGroupsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function toggleFollowCalendar(user, type, id, doing) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    var formdata = new FormData();
    formdata.append('follow_user', user);
    formdata.append('follow_type', type);
    formdata.append('follow_id', id);

    //console.log('user, type, id, doing', user, type, id, doing);
    //console.log(user);
    //console.log(id);
    try {
      const response = await axios.post('/' + doing + '/follow', formdata);
      //console.log(response.data);
      dispatch(slice.actions.onToggleFollowCalendar(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCalendarsFollowing(user_id, type='') {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/get/follows', {
        // id for followers, user for following
        params: { user: user_id, type: type }
      });
      //console.log(response.data);
      dispatch(slice.actions.getCalendarsFollowingSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function saveUserCalendars(formdata, setDoGet = {}) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    //console.log('saveUserCalendars', formdata);
    try {
      const response = await axios.post('/save/user', formdata, {
        headers: { 'X-Requested-With': 'XMLHttpRequest' }
      });
      //console.log('response.data', response.data);
      dispatch(slice.actions.saveUserCalendarSuccess(response.data));
      //setDoGet(true);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
