import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// material
import { Avatar } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

const AvatarStyle = styled(Avatar)(({ theme, styleProps }) => {
  const { color } = styleProps;

  return {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette[color].contrastText,
    backgroundColor: theme.palette[color].main
  };
});

// ----------------------------------------------------------------------

const MAvatar = forwardRef(({ color = 'default', children, ...other }, ref) => {
  if (color === 'default') {
    return (
      <Avatar ref={ref} {...other}>
        {children}
      </Avatar>
    );
  }

  return (
    <AvatarStyle ref={ref} styleProps={{ color }} {...other}>
      {children}
    </AvatarStyle>
  );
});

MAvatar.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf([
    'default',
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error'
  ])
};

export default MAvatar;
