import { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import HomeLayout from '../layouts/home';
// routes
import { PATH_APP } from '../routes/paths';
// ----------------------------------------------------------------------

const HomeRoutes = {
  path: '*',
  layout: HomeLayout,
  routes: [
    {
      exact: true,
      path: '/',
      // component: lazy(() => import('../views/LandingPage'))
      // NEED TO CHANGE THIS WHEN LIVE
      component: () => <Redirect to={PATH_APP.news.home} />
    },
    {
      exact: true,
      path: '/components',
      component: lazy(() => import('../views/ComponentsOverview'))
    },
    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default HomeRoutes;
